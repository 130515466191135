.livro__container {
    padding-top: 40px;
}

.livro__row {
    padding: 0 22%;
    /*font-family: 'Lato', sans-serif;*/
    text-align: justify;
}

.livro_first-rows {
    text-align: center;
}

.livro__signature {
    padding-bottom: 0.5rem;
}

.livro__text {
    /*font-family: 'Lato', sans-serif;*/
    /*font-family: 'Roboto Condensed', sans-serif;*/
    font-size: 1.1rem;
    line-height: 1.5em;
    padding: 0.6rem 0;
    font-weight: 400;
}

.livro__title {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2em;
    letter-spacing: .02em;
    font-weight: 200;
    color: #383838;
}

.livro__subtitle {
    text-align: center;
    font-size: 1.3rem;
    line-height: 2em;
    letter-spacing: .02em;
    font-weight: 100;
    color: rgba(68, 68, 68, 0.8);
    padding-bottom: 20px;
}

.livro__subsubtitle {
    text-align: center;
    font-size: 1rem;
    line-height: 1.5em;
    letter-spacing: .02em;
    font-weight: 100;
    color: rgba(68, 68, 68, 0.83);
}

.livro__chapter-title {
    text-align: center;
    padding-top: 2em;
    padding-bottom: 1em;
}

.livro__span-bold {
    font-weight: bold;
}

.livro__span-italics {
    font-style: italic;
}

.livro__icon {
    font-size: 0.8rem;
}

.livro__icon-dash {
    text-align: center;
}

.separator-space {
    padding-bottom: 2em;
}

@media screen and (max-width: 660px) {
    .livro__title {
        line-height: 1.5em;
        padding-bottom: 15px;
        font-size: 1.3rem;
    }

    .livro__subtitle {
        line-height: 1.3em;
        font-size: 1rem;
        padding-bottom: 2.5rem;
    }

    .livro__row {
        padding: 0 5%;
    }

    .livro__text {
        font-size: 1rem;
        line-height: 1.625rem;
        margin: 0 auto 20px;
        padding: 0 5px;
        text-align: left;
    }

    .livro__text-centered {
        text-align: center !important;
    }

}

@media (min-width: 1460px) {
    .livro__row {
        padding: 0 30%;
    }
}
