.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer-container .footer-text {
    padding: 0;
    margin: 0;
}
.footer-container {
    padding: 2rem 0;
}
.footer-container {
    margin: 0 2px;
}
.footer-text {
    color: rgb(117, 114, 117);
    font-style: italic;
    /*font-size: 2rem;*/
    /*line-height: 2rem;*/
    /*font-weight: 200;*/
    font-family: 'PT Sans Narrow', sans-serif;
    /*font-family: 'Roboto Condensed', sans-serif;*/
    /*font-family: 'Petit Formal Script', cursive;*/
}

@media (max-width: 520px){
    .footer-container {
        padding-right: 10%;
        padding-left: 10%;
        padding-bottom: 10%;
    }

    .footer-text {
        text-align: center;
        font-size: 1rem;
    }
}
