.home__row {
    padding: 0 25%;
}

.home__img_row {
    /*padding-bottom: 50px;*/
}

@media (max-width: 660px){
    .home__row {
        padding: 0 5%;
    }
}

@media (min-width: 660px) and (max-width: 820px){
    .home__row {
        padding: 0 25%;
    }
}

@media (min-width: 820px) and (max-width: 1460px){
    .home__row {
        padding: 0 30%;
    }
}

@media (min-width: 1460px){
    .home__row {
        padding: 0 35%;
    }
}