.testemunho-main__container {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 0;
    padding-right: 20%;
    padding-left: 20%;
    padding-bottom: 5rem;
}

.testemunho-main__cards {
    padding: 20% 10%;
    text-align: center !important;
}

.testemunho-main__img {
    width: 400px;
    height: 400px;
    max-width: 40%;
    opacity: 50%;
}

@media (max-width: 700px) {
    .testemunho-main__container {
        flex-direction: column-reverse;
        padding-bottom: 0;
    }
}
