* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
}

.text {
  font-size: 1.2rem;
  letter-spacing: .02em;
  font-weight: 300;
  font-style: normal;
  position: relative;
  height: auto;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  color: #444444;
  text-align: justify;
}

.title-small {
  font-family: 'Raleway', sans-serif;
  color: #222222;
  font-size: 21px;
  line-height: 1.7em;
  text-transform: none;
  letter-spacing: .03em;
  font-weight: 400;
  font-style: normal;
}

.title-medium {
  font-family: 'Raleway', sans-serif;
  color: #222222;
  text-transform: none;
  letter-spacing: .03em;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  line-height: 1.5em;
}

.separator {
  display: flex;
  justify-content: center;
}

hr {
  width: 80%;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

.App {
  text-align: center;
}

@media (max-width: 600px) {
  .text {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 0 auto 20px;
    padding: 0 5px;
    text-align: left;
  }
}
