.navbar {
    background: white;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
}
.navbar__container {
    display: flex;
    justify-content: space-between;
    height: 100px;
}
.container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
}
.navbar-logo {
    color: #666666;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.navbar-icon {
    margin-right: 0.5rem;
}

.menu-icon {
    display: none;
}

.navbar .container .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
}
.navbar .container .nav-menu .nav-item {
    height: 100px;
    border-bottom: 2px solid transparent;
}
.navbar .container .nav-menu .nav-item .nav-links {
    color: #666;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 0.7rem;
    height: 100%;
}
.navbar .container .nav-menu .nav-item:hover {
    border-bottom: 2px solid black;
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noSelect:focus {
    outline: none !important;
}

.hidden{
    height: 80px;
    width: 100%;
    z-index: 40;
    position: fixed;
    top: -80px;
    transition: 0.3s linear;
}


@media only screen and (max-width: 1225px) {
    .navbar {
        padding: 0 24px;
    }
}

@media screen and (max-width: 660px) {

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -150%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: rgb(56, 56, 56);
        left: 0;
        opacity: 1;
        transition: all 0.6s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 3rem;
        width: 100%;
        display: grid;
    }

    .navbar .container .nav-menu .nav-item .nav-links {
        color: rgb(255, 255, 255);
        /*padding: 0.5rem 45%;*/
        padding: 2rem 0;
    }

    .navbar .container .nav-menu .nav-item .nav-links {
        display: flex;
        align-items: center;
        text-decoration: none;
        flex-direction: column;
    }

    .navbar .container .nav-menu .nav-item:hover {
        border-bottom: 0.1rem solid;
    }

    .navbar .container .nav-menu .nav-item:hover {
        color: rgba(9, 240, 159, 0);
    }

    .nav-item:hover {
        border: none;
    }

    .nav-item {
        width: 100%;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: rgba(204, 204, 204, 0.91);
    }

    .navbar-logo {
        display: block;
        position: absolute;
        top: 23px;
        left: 20px;
        font-size: 1.5rem;
    }

}
