.testemunho-image-source__img {
    max-width: 90%;
    min-width: 250px;
    padding-left: 30%;
    /*border-radius: 10px;*/
    opacity: 90%;
}

@media (min-width: 1460px) {
    .testemunho-image-source__img {
        max-width: 80%;
    }
}

@media (max-width: 700px) {
    .testemunho-image-source__img {
        max-width: 70%;
        min-width: 200px;
    }
}
@media (max-width: 350px) {
    .testemunho-image-source__img {
        padding-left: 0;
        min-width: 150px;
    }
}
