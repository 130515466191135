.testemunho-group__container {
    padding: 0 22%;
}

.testemunho-group__card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100px;
    border: 1px solid rgba(201, 198, 198, 0.34);
    border-radius: 10px;
    text-align: center;
}

.testemunho-group__text {

}