.card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*margin: 1rem 20%;*/
    border: 1px solid rgba(201, 198, 198, 0.34);
    border-radius: 10px;
    text-align: center;
}

.card-title {
    padding: 1rem;
}

.noSelectCard {
    text-decoration: none;
    color: #666666;
    justify-self: start;
    cursor: pointer;
}

@media (max-width: 600px) {
    .card-container {
        /*margin: 1rem 2%;*/
    }
}
